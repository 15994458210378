import { memo, useState, useEffect } from "react";

const ComLay = memo((props) => {
    const [clientHistory, setClientHistory] = useState(null);


    function formatMessageDate(dateString) {
        const messageDate = new Date(dateString);
        const now = new Date();
        const isToday = messageDate.toDateString() === now.toDateString();
        const timeDifference = now - messageDate;
        const oneHour = 60 * 60 * 1000;
        const oneDay = 24 * oneHour;
    
        if (isToday && timeDifference < oneHour) {
            return messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        } else if (isToday) {
            return messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        } else if (timeDifference < oneDay) {
            return 'Вчера';
        } else {
            const day = String(messageDate.getDate()).padStart(2, '0');
            const month = String(messageDate.getMonth() + 1).padStart(2, '0'); // Добавляем 1 к месяцу
            const year = messageDate.getFullYear();
            return `${day}.${month}.${year}`;
        }
    }


    useEffect(() => {
        const fetchClientHistory = async () => {
            try {
                console.log("Fetching data with ID:", props.data._id); // Add this line for debugging
                const response = await fetch(`https://helpex.tech/api/v1/panel/getClientHistory/${props.data.from_user}`);
                const data = await response.json();
                setClientHistory(data);
                console.log(data);
            } catch (error) {
                console.error("Error fetching client history:", error);
            }
        };

        if (props.data._id) { // Check if the ID is available
            fetchClientHistory();
        }
    }, [props.data._id]); // Use props.data._id as dependency

    return (
        <>
                {
                    clientHistory && clientHistory.map((item) => (
                        <>
                            <div className="user-history-opeoations-block">
                                <span>Сделка #{item._id}</span>
                                <span>К оплате {item.need_pay}</span>
                                <span>Скидка {item._id} | Курс {item.rate}</span>
                                <span>{formatMessageDate(item.creation_date)} {item.manager_username}</span>
                            </div>
                        </>
                            
                        ))
                        
                    }

            <form className="fast-wrap" id="add">
                    <div className="list-3-group" style={{backgroundColor: 'unset'}}>
                        <span className="list-3-title">Информация по сделке</span>
                    </div>
                    <textarea name=""></textarea>
                    <div className="list-3-group" style={{backgroundColor: 'unset'}}>
                        <span className="list-3-title">Информация по клиенту</span>
                    </div>
                    <textarea name=""></textarea>
            </form>
        </>
        
        
    );
});

export default ComLay; 
